import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    DETAILED: "Details",
    MOCKUPS: "Mockups",
    YOUTUBE: "Youtube Videos",
    VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "TrailerFlix",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Website where you can watch movie trailers and movie information. There is an admin user who can add, edit and remove movies or movies information. I created an API for this project",
        technologies: "Javascript, HTML5, CSS, Bootstrap, Node JS, Express js, MongoDB Atlas",
        github: {
          name: "Movie Trailer Project GitHub",
          link: "https://github.com/RaimyMarte/movie-trailer-project",
        },
        url: {
          name: "TrailerFlix",
          link: "https://movie-trailer-project.netlify.app",
        },

        sliderImages: [
          "images/projects/movie-trailer-project/2.png",
          "images/projects/movie-trailer-project/3.png",
          "images/projects/movie-trailer-project/4.png",
          "images/projects/movie-trailer-project/5.png",
          "images/projects/movie-trailer-project/6.png",
          "images/projects/movie-trailer-project/7.png",
          "images/projects/movie-trailer-project/8.png",
          "images/projects/movie-trailer-project/9.png",
          "images/projects/movie-trailer-project/10.png",
          "images/projects/movie-trailer-project/11.png",
        ],
      },

      thumbImage: "images/projects/movie-trailer-project/1.png",

      categories: [filters.DETAILED],
    },
    {
      title: "E&M Services Medical",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Landing Page for E&M Services Medical, laboratory analyzers, equipment, reagents, technical services and more.",
        technologies: "Javascript, React, CSS, Material UI, Framer Motion",
        github: {
          name: "E&M Services Medical GitHub",
          link: "https://github.com/RaimyMarte/e-m-services-medical",
        },
        url: {
          name: "E&M Services Medical",
          link: "https://emservicesmedical.netlify.app",
        },

        sliderImages: [
          "images/projects/e-m-services-medical/2.png",
          "images/projects/e-m-services-medical/3.png",
          "images/projects/e-m-services-medical/4.png",
        ],
      },

      thumbImage: "images/projects/e-m-services-medical/1.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Heroes",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Single page web where you can see Marvel and DC heroes information.",
        technologies: "Javascript, React, CSS, Bootstrap",
        github: {
          name: "Heroes GitHub",
          link: "https://github.com/RaimyMarte/heroes-spa",
        },
        url: {
          name: "Heroes",
          link: "https://heroes-single-page-raimy.netlify.app/",
        },

        sliderImages: [
          "images/projects/heroes-single-page/2.png",
          "images/projects/heroes-single-page/3.png",
          "images/projects/heroes-single-page/4.png",
        ],
      },

      thumbImage: "images/projects/heroes-single-page/1.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Pokemon",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Web page where you can see every Pokemon information, picture and evolution.",
        technologies: "Javascript, CSS, Bootstrap",
        github: {
          name: "Pokemon GitHub",
          link: "https://github.com/RaimyMarte/PokemonAPI-project",
        },
        url: {
          name: "Pokemon",
          link: "https://pokemonapi-project.netlify.app",
        },

        sliderImages: [
          "images/projects/pokemonapi-project/2.png",
          "images/projects/pokemonapi-project/3.png"
        ],
      },

      thumbImage: "images/projects/pokemonapi-project/1.png",

      categories: [filters.DETAILED],
    },
    // {
    //   title: "Detailed Project 1",
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //     client: "Ruby Clinton",
    //     technologies: "iOS, HTML5, CSS3, PHP, Java",
    //     industry: "Art & Design",
    //     date: "July 16, 2019",
    //     url: {
    //       name: "www.example.com",
    //       link: "https://www.example.com",
    //     },

    //     sliderImages: [
    //       "images/projects/project-2.jpg",
    //       "images/projects/project-5.jpg",
    //     ],
    //   },

    //   thumbImage: "images/projects/project-1.jpg",

    //   categories: [filters.DETAILED],
    // },
    // {
    //   title: "Mockups Design 1",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-2.jpg",

    //   categories: [filters.MOCKUPS],
    // },
    // {
    //   title: "YouTube Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: false,
    //     id: "PMNnEEEacCg",
    //   },
    //   thumbImage: "images/projects/project-3.jpg",

    //   categories: [filters.YOUTUBE],
    // },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },

    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },
    // {
    //   title: "Detailed Project 2",
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //     client: "Ruby Clinton",
    //     technologies: "iOS, HTML5, CSS3, PHP, Java",
    //     industry: "Art & Design",
    //     date: "July 16, 2019",
    //     url: {
    //       name: "www.example.com",
    //       link: "https://www.example.com",
    //     },
    //     sliderImages: [
    //       "images/projects/project-1.jpg",
    //       "images/projects/project-2.jpg",
    //     ],
    //   },
    //   thumbImage: "images/projects/project-5.jpg",
    //   categories: [filters.DETAILED],
    // },
    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project-6.jpg",

    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          {/* <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul> */}
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            {/* <div className="row portfolio-filter filter-container g-4"> */}
            <div className="row row-cols-1  row-cols-md-2 portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  // <div
                  //   className={
                  //     "col-sm-6 col-md-4 filter-item " +
                  //     getFilterClasses(project.categories)
                  //   }
                  //   key={index}
                  // >
                  <div
                    className={
                      "filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            {/* <span className="text-light">Category</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
